// google analytics
import { config } from '@/conf'

// @ts-ignore
window.dataLayer = window.dataLayer || []
// @ts-ignore
function gtag () { dataLayer.push(arguments) }

// tag ids per host
const ids: { [key: string]: string } = {
  'mobefind-at.com': 'G-T6FH6JQDTM',
  'mobefind-be.com': 'G-XY55XV9JYW',
  'mobefind-ca.com': 'G-LQ980TYGZL',
  'mobefind-ch.com': 'G-GPXZYKECPX',
  'mobefind-cn.com': 'G-D92XLH05L',
  'mobefind-cz.com': 'G-M8H3C6QVFG',
  'mobefind-de.com': 'G-6LBHS7DH5B',
  'mobefind-fr.com': 'G-YDRZ7YW7GY',
  'mobefind-it.com': 'G-WRG5QTD19E',
  'mobefind-nl.com': 'G-LTDG8GVTVP',
  'mobefind-pl.com': 'G-6BYGZCW6LB',
  'mobefind-pt.com': 'G-CRK8GHS2Z5',
  'mobefind-ro.com': 'G-EKBBYPRPS7',
  'mobefind-se.com': 'G-7V9NRZY4VF',
  'mobefind-ie.com': 'G-D0HB6YB2L2'
}

// initializes gtag
const init_gtag = (aw_tag: string) => {
  // gtag uri
  const uri = 'https://www.googletagmanager.com/gtag/js?id='

  let script = document.createElement('script');
  script.setAttribute('src', uri + aw_tag);
  document.head.appendChild(script);

  if (ids[config.host]) {
    config.gtag = ids[config.host]
    script = document.createElement('script');
    script.setAttribute('src', uri + ids[config.host]);
    document.head.appendChild(script);
  } else {
    console.error(`domain ${config.host} not configured for tag manager`)
  }
}

export { init_gtag, gtag }
