<template>
  <div class="showcase section">

    <span class="svg-layer-wrapper">
      <span xyz="fade delay-1 duration-8 xyz-in">
        <svg version="1.1" class="svg-layer" xmlns="http://www.w3.org/2000/svg"
             xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 85 79" style="enable-background:new 0 0 85 79;" xml:space="preserve">
            <path d="M72.7,12.9c6.6,6.1,11.8,12.5,11.3,18.4c-0.5,5.9-6.8,11.5-10.4,19.9c-3.5,8.5-4.3,19.8-10.6,24.6c-6.3,4.8-18.1,3-27.7-1.2
              c-9.6-4.2-17.2-10.8-23.8-18.8C5,47.9-0.7,38.5,1.3,30.6c1.9-7.9,11.6-14.4,21-20.1C31.7,4.8,40.7-0.2,49.3,0.3
              C58,0.8,66.1,6.7,72.7,12.9z"/>
            </svg>
      </span>
    </span>

    <div class="container">

      <div class="row">

        <div class="col-md-6 mockup">

          <img src="@/assets/mockup-2.png" alt="Locate any phone, anywhere" class="xyz-in" xyz="fade left-5 delay-2 duration-8"/>

         <!-- <div class="alert">
            <div>{{ strings.home.main[18] }}</div>
            <div>{{ strings.home.main[20] }}</div>
          </div>-->
        </div>

        <div class="col-md-6">

          <h2>
            {{ strings.home.main[9] }}
          </h2>

          <ul class="list icons mt-2">
            <li>
              <icon icon="arrowRight"></icon>
              {{ strings.home.main[10] }}
            </li>
            <li>
              <icon icon="arrowRight"></icon>
              {{ strings.home.main[11] }}
            </li>
            <li>
              <icon icon="arrowRight"></icon>
              {{ strings.home.main[12] }}
            </li>
            <li>
              <icon icon="arrowRight"></icon>
              {{ strings.home.main[13] }}
            </li>
            <li>
              <icon icon="arrowRight"></icon>
              {{ strings.home.main[14] }}
            </li>
          </ul>

          <h4 class="mt-4">{{ strings.home.how[1] }}</h4>
          <icon-block icon="dial" color="secondary" class="mt-2">
            <h5>{{ strings.home.how[4] }}</h5>
            <p class="mt-025">{{ strings.home.how[5] }}</p>
          </icon-block>
          <icon-block icon="alert" color="secondary" class="mt-15">
            <h5>{{ strings.home.how[6] }}</h5>
            <p class="mt-025">{{ strings.home.how[7] }}</p>
          </icon-block>
          <icon-block icon="map" color="secondary" class="mt-15">
            <h5>{{ strings.home.how[8] }}</h5>
            <p class="mt-025">{{ strings.home.how[9] }}</p>
          </icon-block>

        </div>

      </div>

    </div>
  </div>

</template>

<script>
import { strings } from '@/strings'
import Icon from '@/components/icons/icon'
import IconBlock from '@/components/icons/icon-block'

export default {
  name: 'Showcase',
  components: { Icon, IconBlock },
  setup () {
    return { strings }
  }
}
</script>
