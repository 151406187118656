import { XHR } from '@/utils/xhr'

let user_geo: string

const get_geo = () => {
  return new Promise<string>((resolve, reject) => {
    const xhr = new XHR()
    xhr.get('/geo').then(iso2 => {
      if (iso2.data) {
        user_geo = iso2.data
        resolve(iso2.data)
      }
    }).catch(e => {
      reject(e)
    })
  })
}

export { get_geo, user_geo }
