
import { config } from '@/conf'
import { languages, country_iso2 } from '@/utils/language'

export default {
  name: 'LangSelectModal',
  setup () {
    const flag = (l: string) => {
      return `https://hatscripts.github.io/circle-flags/flags/${country_iso2[l]}.svg`
    }

    return {
      config, languages, flag
    }
  }

}
