import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "v-home" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_hero = _resolveComponent("hero")!
  const _component_features = _resolveComponent("features")!
  const _component_showcase = _resolveComponent("showcase")!
  const _component_home_faq = _resolveComponent("home-faq")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_hero),
    _createVNode(_component_features),
    _createVNode(_component_showcase),
    _createVNode(_component_home_faq),
    _createVNode(_component_router_view)
  ]))
}