import { loadStripe, Stripe, StripeElementLocale } from '@stripe/stripe-js'
import { config } from '@/conf'
import { XHR } from '@/utils/xhr'
import { localstore } from '@/utils/localstorage'


class Checkout {
  stripe!: Stripe
  spk!: string
  trial_pricing!: {[key: string]: number}
  sub_pricing!: {[key: string]: number}
  trial_price!: () => string
  subscription_price!: () => string
  on_change?: () => void

  constructor () {
    const xhr = new XHR()
    // set pre-defined pricing (for dev)
    this.trial_pricing = {}
    this.sub_pricing  = {}

    // fetch stripe public key and pricing from server
    xhr.get('/stripe').then(r => {
      const resp_data = r.decode<StripeInfo>()
      if (!resp_data) { console.error('failed to get stripe info'); return }
      // set pub key
      this.spk = resp_data!.spk
      // set updated pricing
      this.trial_pricing = resp_data!.trial_pricing
      this.sub_pricing = resp_data!.sub_pricing

      // set default currency when euro currency is not part of product
      // and currency is not set by url
      const currencies = Object.keys(this.trial_pricing)
      if (!currencies.includes('eur') && !localstore.get('currency')) {
        localstore.set('currency', currencies[0])
      }

      // load pricing for currency (stored in local storage)
      this.trial_price = trial_price_multi_cur
      this.subscription_price = subscription_price_multi_cur

      // trigger event listener
      if (this.on_change) { this.on_change() }

    }).catch(e => {
      console.error(e)
      console.error("failed to get stripe public key")
    })

    /*
    // multi-currency
    if (config.host === 'mobefind.com' ||
      config.host === 'mobefind-eu.com' ||
      config.host === 'mobefind-ca.com' ||
      config.host === 'mobefind-cn.com' ||
      config.host === 'mobefind-es.com' ||
      config.host === 'mobefind-pt.com' ||
      config.host === 'mobefind-uk.com' ||
      config.host === 'mobefind-fr.com'
    ) {
      // fetch stripe public key and pricing from server
      xhr.get('/stripe').then(r => {
        const resp_data = r.decode<StripeInfo>()
        if (!resp_data) { console.error('failed to get stripe info'); return }
        // set pub key
        this.spk = resp_data!.spk
        // set updated pricing
        this.trial_pricing = resp_data!.trial_pricing
        this.sub_pricing = resp_data!.sub_pricing
        this.trial_price = TrialPrice()
        this.subscription_price = SubscriptionPrice()

        if (this.on_change) { this.on_change() }

      }).catch(e => {
        console.error("failed to get stripe public key")
      })
    } else {
      this.trial_price = TrialPrice()
      this.subscription_price = SubscriptionPrice()

      // non multi-currency
      // initializes stripe.js module
      xhr.get('/spk').then(x => {
        this.spk = x.data!
      }).catch(e => {
        console.error("failed to get stripe public key")
      })
    }

     */
  }


  // initializes stripe.js module
  init () {
    // @ts-ignore
    loadStripe(this.spk).then((stripe: Stripe) => {
      this.stripe = stripe
    })
  }

  // load_pricing () {
  //   this.trial_price()
  //   this.subscription_price()
  // }

  // launch checkout process
  launch (props: CheckoutConfig) {
    if (!check_card()) {
      return false
    }

    // init stripe elements
    const elements = this.stripe.elements({
      clientSecret: props.token,
      locale: (config.lang.iso === 'latam' ? 'es-419' : config.lang.iso) as StripeElementLocale
    })

    // create and mount stripe element
    const payment_el = elements.create('payment', { terms: {card: 'never'} })
    payment_el.mount('#payment-element')

    // on payment element 'ready' event. element is rendered and mounted
    payment_el.on('ready', (event) => {
      // fire on_ready handler
      props.on_ready()

      // submit event listener
      const form = document.getElementById('payment-form')!
      form.addEventListener('submit', async (event) => {
        event.preventDefault()

        // fire on_busy handler
        props.on_busy(true)

        // confirm setup intent
        const { error } = await this.stripe.confirmPayment({
          elements, confirmParams: {
            return_url: checkout_complete_url(props.uid),
            // billing_details: {
            //  name: nameInput.value,
            // },
          }
        })

        // immediate error (invalid card data ect.)
        if (error) {
          // ******-----
          console.log(error)
          // ------*****

          // card declined
          if (error.type == 'card_error') {
            // get card check num and increment
            let n = check_card_n(); n ++
            localstore.set('XNpD5J8X', n+'NpD5J8X')
          }
          // fire on_error handler
          props.on_error(error.message!)
          // fire on_busy handler
          props.on_busy(false)
        } else {
          // customer will be redirected to an intermediate site to authorize the payment,
          // then redirected to the `return_url`

          // fire on_busy handler
          props.on_busy(false)
        }
      })
    })

    // fire on_change event when user focus on element (to hide any previous errors)
    payment_el.on('focus', (event) => {
      props.on_change()
    })

    return true
  }
}

// checks card status
const check_card = () => {
  const n = check_card_n()
  // error
  if (n === -1) {
    return false
  }
  // no data
  else if (n === 0) {
    return true
  }
  // limit reached
  else if (n > 1) {
    return false
  }
  return true
}
const check_card_n = () => {
  const data = localstore.get('XNpD5J8X')
  if (data) {
    const count = data.substring(0,1)
    const num = parseInt(count);
    if (!num) {
      console.error('check_card NaN')
      return -1
    }
    return num
  }
  return 0
}

// checkout configuration
interface CheckoutConfig {
  uid       : string
  token     : string
  on_ready  (): void
  on_busy   (_:boolean): void
  on_error  (_:string): void
  on_change (): void
}

// returns the url where the user is directed after completing checkout
const checkout_complete_url = (uid: string) : string => {
  // dev mode
  if (process.env.NODE_ENV === 'development') {
    console.log("checkout configured in dev mode [endpoint: http://127.0.0.1:4444]")
    return "http://127.0.0.1:4444/checkout?uid="+uid+"&lang="+config.lang.iso
  }

  // append user language iso2 code if the language is set in url (forced)
  if (config.lang.in_url) {
    // url with app domain name defined in config, user id and user language
    return "https://"+config.host+"/checkout?uid="+uid+"&lang="+config.lang.iso
  }

  // url with app domain name defined in config
  return "https://"+config.host+"/checkout?uid="+uid
}


// global checkout class
let checkout: Checkout
const InitCheckout = () => {
  checkout = new Checkout()
  // checkout.load_pricing()
}

// currency symbols
const currency_formats_2: {[key: string]: string} = {
  "eur": "€{n}",
  "gbp": "£{n}",
  "krw": "₩{n}",
  "sar": "{n} SR",
  "aed": "{n}د.إ",
  "dkk": "{n} kr",
  "czk": "{n} Kč",
  "sek": "{n} kr",
  "huf": "{n} Ft",
  "jpy": "¥{n}",
  "usd": "${n}",
  "aud": "${n}",
  "sgd": "${n}",
  "ron": "{n} lei",
  "cad": "${n}",
  "pln": "{n}zł",
  "nok": "{n} kr",
  "nzd": "${n}",
  "chf": "{n} Fr",
  "ils": "₪{n}",
  "qar": "QR {n}",
  "brl": "R${n}",
  "pen": "S/{n}",
  "ars": "$ {n}",
  "clp": "$ {n}",
  "egp": "£ {n}",
  "try": "₺{n}",
  "cop": "$ {n}",
  "mxn": "$ {n}",
  "uah": "₴{n}",
  "zar": "R{n}"
}

const currency_formats: {[key: string]: string} = {
  aed: "{n} AED",
  afn: "Af {n}",
  all: "ALL {n}",
  amd: "AMD {n}",
  ars: "${n} <small class='xs'>ARS</small>",
  aud: "${n} <small class='xs'>AUD</small>",
  azn: "₼{n}",
  bam: "KM {n}",
  bbd: "Bds$ {n}",
  bdt: "Tk {n}",
  bgn: "лв{n}",
  bhd: "BD {n}",
  bif: "FBu {n}",
  bnd: "BN$ {n}",
  bob: "Bs {n}",
  brl: "R$ {n}",
  bsd: "B$ {n}",
  bwp: "P{n}",
  byn: "Br {n}",
  bzd: "BZ$ {n}",
  cad: "${n}",
  cdf: "FC{n}",
  chf: "{n} CHF",
  clp: "$ {n} <small class='xs'>CLP</small>",
  cny: "CN¥ {n}",
  cop: "$ {n} <small class='xs'>COP</small>",
  crc: "₡ {n}",
  cve: "CV$ {n}",
  czk: "{n} Kč",
  djf: "Fdj {n}",
  dkk: "{n} kr. <small class='xs'>DKK</small>",
  dop: "RD$ {n}",
  dzd: "DA {n}",
  eek: "Ekr {n}",
  egp: "EGP {n}",
  ern: "Nfk {n}",
  etb: "Br {n}",
  eur: "€ {n}",
  eur2: "€ {n}",
  eur3: "€ {n}",
  gbp: "£ {n}",
  gel: "GEL {n}",
  ghs: "FC {n}",
  gnf: "FG {n}",
  gtq: "Q{n}",
  hkd: "HK$ {n}",
  hnl: "HNL {n}",
  hrk: "kn {n}",
  htg: "G{n}",
  huf: "{n} Ft",
  idr: "Rp {n}",
  ils: "₪ {n}",
  inr: "₹{n}",
  iqd: "IQD {n}",
  irr: "IRR {n}",
  isk: "Ikr {n}",
  jmd: "J$ {n}",
  jod: "JD {n}",
  jpy: "¥ {n}",
  kes: "Ksh {n}",
  khr: "KHR {n}",
  kmf: "CF {n}",
  krw: "₩ {n}",
  kwd: "KD {n}",
  kzt: "KZT {n}",
  lbp: "L.L. {n}",
  lkr: "SLRs {n}",
  ltl: "Lt {n}",
  lvl: "Ls {n}",
  lyd: "LD {n}",
  mad: "Dirham {n}",
  mdl: "MDL {n}",
  mga: "MGA {n}",
  mkd: "MKD {n}",
  mmk: "MMK {n}",
  mop: "MOP$ {n}",
  mur: "MURs {n}",
  mxn: "MX$ {n}",
  mxn2: "MX$ {n}",
  myr: "RM {n}",
  mzn: "MTn {n}",
  nad: "N$ {n}",
  ngn: "₦{n}",
  nio: "C$ {n}",
  nok: "{n} kr",
  npr: "NPRs {n}",
  nzd: "$ {n} <small class='xs'>NZD</small>",
  omr: "OMR {n}",
  pab: "B/. {n}",
  pen: "S/. {n}",
  php: "₱ {n}",
  pkr: "PKRs {n}",
  pln: "{n} zł",
  pyg: "₲ {n}",
  qar: "QR {n}",
  ron: "{n} lei",
  rsd: "din. {n}",
  rub: "RUB {n}",
  rwf: "RWF {n}",
  sar: "ر.س {n}",
  sdg: "$ {n} <small class='xs'>SDG</small>",
  sek: "{n} kr",
  sgd: "S$ {n}",
  sos: "Ssh {n}",
  syp: "SY£ {n}",
  thb: "฿ {n}",
  tnd: "DT {n}",
  top: "T$ {n}",
  try: "TL {n}",
  ttd: "TT$ {n}",
  twd: "NT$ {n}",
  tzs: "TSh {n}",
  uah: "₴ {n}",
  ugx: "USh {n}",
  usd: "$ {n}",
  usd2: "$ {n}",
  usd3: "$ {n}",
  uyu: "$U {n}",
  uzs: "UZS {n}",
  vef: "Bs.F. {n}",
  vnd: "₫ {n}",
  xaf: "FCFA {n}",
  xcd: "EC$ {n}",
  xof: "CFA {n}",
  yer: "YR {n}",
  zar: "R {n}",
  zmk: "ZK {n}",
  zwl: "ZWL$"
}

if (config.lang.iso !== 'ar') {
  currency_formats['sar'] = "{n} SAR"
}

const trial_price_multi_cur = () => {
  // get currency from session storage
  const currency = localstore.get('currency') ?? 'eur'
  // get amount int
  let amount = checkout.trial_pricing[currency] ?? 0
  if (currency !== 'jpy') {
    amount = amount/100
  }

  let price: string

  if (currency === 'huff') {
    price = currency_formats[currency].replace('{n}', amount.toFixed(0))
  } else {
    price = currency_formats[currency].replace('{n}', amount.toFixed(2))
  }

  if (currency === 'bgn' || currency === 'chf') {
    price = price.replace('.', ',')
  }

  return price
}
const subscription_price_multi_cur = () => {
  // get currency from session storage
  const currency = localstore.get('currency') ?? 'eur'
  // get amount int
  let amount = checkout.sub_pricing[currency] ?? 0
  if (currency !== 'jpy') {
    amount = amount/100
  }

  let price : string

  if (currency === 'huff') {
    price = currency_formats[currency].replace('{n}', amount.toFixed(0))
  } else {
    price = currency_formats[currency].replace('{n}', amount.toFixed(2))
  }

  if (currency === 'bgn' || currency === 'chf') {
    price = price.replace('.', ',')
  }

  return price
}

// stripe pub key and pricing
interface StripeInfo {
  spk           : string
  trial_pricing : {[key: string]: number}
  sub_pricing   : {[key: string]: number}
}

export { checkout, CheckoutConfig, InitCheckout, currency_formats }
