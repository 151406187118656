
import { icons } from './icons'

export default {
  name: 'Icon',
  props: {
    icon: {
      type: String,
      default: null
    }
  },
  setup (props: any) {
    const icon_name: string = props.icon

    const html = `
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="100%" width="100%"
        viewBox="0 0 20 20"
        class="svg-fill"
      >
        ${icons[icon_name]}
      </svg>
    `

    return { html }
  }
}
