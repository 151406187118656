
import { strings } from '@/strings'
import { ref } from 'vue'
import { router } from '@/router'
import VIcon from '@/components/icons/icon.vue'
import LangSelect from '@/components/ui/lang-select.vue'

export default {
  name: 'Navbar',
  components: { LangSelect, VIcon },
  setup () {
    // mobile nav state
    const active = ref(false)

    // user access reactive property
    const access = ref(false)

    // mobile nav toggle
    const toggle = (state: boolean) => {
      if (state) {
        active.value = true
        document.body.classList.add('nav-open')
      } else {
        active.value = false
        document.body.classList.remove('nav-open')
      }
    }

    // router function
    const route = (path: string) => {
      toggle(false)
      router.push(path)
    }

    return { active, access, strings, toggle, route }
  },
  /*methods: {
    parse (x) {
      if (this.$locale === 'ru') {
        if (x === 'Часто задаваемые вопросы') {
          return 'Часто задаваемые <br>вопросы'
        } else if (x === 'Контактная информация') {
          return 'Контактная <br>информация'
        } else if (x === 'Удалить подписку') {
          return 'Удалить <br>подписку'
        }
      }
      return x
    }
  }*/
}
