import App from './App.vue'
import { createApp } from 'vue'
import { router } from './router'
import { on_blur } from '@/utils/on_blur'
import './styl/app.styl'
import './utils/language'

const app = createApp(App)
app.use(router)
app.directive("blur", on_blur)
app.mount('#app')

