
import { modal } from '@/components/main/modal'
import { ref } from 'vue'

export default {
  name: 'Modal',
  setup () {
    const active = ref(false)
    const visible = ref(false)
    const component = () => { return modal.component }

    // set modal state handlers
    modal.on_activate = () => {
      active.value = true
      setTimeout(() => { visible.value = true }, 50)
    }
    modal.on_deactivate = () => {
      visible.value = false
      setTimeout(() => { active.value = false }, 50)
    }

    // modal close func
    const close = () => {
      modal.close()
    }

    return {
      active, visible, component, close, modal
    }
  }
}
