import { Directive } from 'vue'

const on_blur: Directive = {
  beforeMount (el, binding, vnode) {
    el.blur_event = function (event: Event) {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event)
      }
    }
    document.body.addEventListener('click', el.blur_event)
  },
  beforeUnmount (el) {
    document.body.removeEventListener('click', el.blur_event)
  }
}

export { on_blur }
