const parse_text_input = (val: string) : string|undefined => {
  // input has value
  if (!val) { return undefined }
  // trim and check string length
  let out = val.trim()
  if (out.length < 0) { return undefined }
  return out
}

export {
  parse_text_input
}
