import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, resolveDirective as _resolveDirective } from "vue"

const _hoisted_1 = { class: "dial-select" }
const _hoisted_2 = {
  key: 0,
  style: {"height":"15px","width":"20px","background-color":"#d1d1d7","border-radius":"4px","margin-right":"0.75rem"}
}
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = {
  key: 0,
  class: "menu"
}
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dialcode_flag = _resolveComponent("dialcode-flag")!
  const _directive_blur = _resolveDirective("blur")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", {
      class: "toggle",
      onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.toggle()))
    }, [
      (!$data.mounted)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2))
        : _createCommentVNode("", true),
      ($data.mounted)
        ? (_openBlock(), _createElementBlock("img", {
            key: 1,
            src: `/img/flags/${$data.selected.code.toLowerCase()}.svg`,
            alt: $data.selected.code
          }, null, 8, _hoisted_3))
        : _createCommentVNode("", true),
      _createElementVNode("span", null, _toDisplayString($data.selected.dial_code), 1)
    ]),
    ($data.mounted)
      ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.countries, (c, i) => {
            return (_openBlock(), _createElementBlock("a", {
              key: i,
              onClick: ($event: any) => ($options.on_select(c))
            }, [
              _createVNode(_component_dialcode_flag, { country: i }, null, 8, ["country"]),
              _createElementVNode("span", null, _toDisplayString(c.dial_code), 1),
              _createElementVNode("span", null, _toDisplayString(c.name), 1)
            ], 8, _hoisted_5))
          }), 128))
        ], 512)), [
          [_vShow, $data.active]
        ])
      : _createCommentVNode("", true)
  ])), [
    [_directive_blur, $options.hide]
  ])
}