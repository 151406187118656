import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "col-md-6" }
const _hoisted_2 = { class: "text-block" }
const _hoisted_3 = {
  class: "xyz-in",
  xyz: "fade down-2 duration-8 delay-1"
}
const _hoisted_4 = {
  class: "lead mt-2 xyz-in",
  xyz: "fade down duration-8 delay-3"
}
const _hoisted_5 = {
  class: "lead mt-2 xyz-in",
  xyz: "fade duration-8 delay-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_phone_input = _resolveComponent("phone-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", _hoisted_3, _toDisplayString($data.strings.core["Slogan"]), 1),
      _createElementVNode("p", _hoisted_4, _toDisplayString($data.strings.core['PhoneError']), 1)
    ]),
    _createVNode(_component_phone_input, {
      onReady: $options.submit,
      ref: "phone-input",
      btn_text: $data.strings.core['Start'],
      class: "mt-3 xyz-in",
      xyz: "fade duration-8 delay-3"
    }, null, 8, ["onReady", "btn_text"]),
    _createElementVNode("p", _hoisted_5, _toDisplayString($data.strings.core['Description']), 1)
  ]))
}