import { PhoneNumber, parsePhoneNumber } from 'libphonenumber-js'
import * as EmailValidator from 'email-validator'
import { ParsedPhoneNumber } from '@/utils/locator'

// validates the given email address
const validate_email = (email: string) : boolean => {
  return EmailValidator.validate(email)
}

// validates the given phone number
const validate_phone = (dial: string, no: string): ParsedPhoneNumber|undefined => {
  // parse phone number
  const parsed: PhoneNumber = parsePhoneNumber(dial + no) as PhoneNumber

  // nil
  if (!parsed) { return }
  if (!parsed.isValid()) { return }

  // format number
  const formatted = parsed.formatInternational()

  return {
    dial:      dial,
    number:    parsed.nationalNumber,
    formatted: formatted,
    country:   parsed.country as string
  }
}

export {
  validate_email, validate_phone
}
