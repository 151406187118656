const languages: { [key: string]: string }  = {
  bg: 'български',
  cs: 'Česky',
  da: 'Dansk',
  de: 'Deutsch',
  el: 'ελληνικός',
  en: 'English',
  es: 'Español',
  latam: 'Español (LATAM)',
  et: 'Eesti keel',
  fi: 'Suomalainen',
  fr: 'Français',
  ga: 'Gaeilge',
  hr: 'Hrvatski',
  hi: 'हिन्दी',
  hu: 'Magyar',
  is: 'íslenskur',
  it: 'Italiano',
  id: 'Bahasa Indonesia',
  ja: "日本語",
  my: 'ဗမာဘာသာစကား',
  nl: 'Nederlands',
  no: 'Norsk',
  pl: 'Polski',
  pt: 'Português',
  ro: 'Românesc',
  ru: 'русский',
  sl: "Slovenščina",
  sv: 'Svenska',
  tr: "Türkçe",
  zh: "普通话"
}

const country_iso2: { [key: string]: string }  = {
  ar: 'sa',
  cs: 'cz',
  bg: 'bg',
  da: 'dk',
  de: 'de',
  et: 'ee',
  el: 'gr',
  en: 'gb',
  es: 'es',
  latam: 'mx',
  fr: 'fr',
  hu: 'hu',
  ga: 'ie',
  id: 'id',
  ko: 'kr',
  it: 'it',
  is: 'is',
  hi: 'in',
  hr: 'hr',
  nl: 'nl',
  no: 'no',
  my: 'my',
  fi: 'fi',
  pl: 'pl',
  pt: 'pt',
  ro: 'ro',
  sl: 'sl',
  sv: 'sv',
  sk: 'sk',
  th: 'th',
  ru: 'ru',
  ms: 'my',
  tr: 'tr',
  zh: 'cn',
  ja: 'jp',
  vi: 'vn',
  uk: 'ua'
}


interface Language {
  iso    : string
  in_url : boolean
  country : string
}

// check if uri contains a language iso2 code
const uri_lang = window.location.pathname.replace(/^\/([^\/]+).*/i, '$1')

// gets current language data
const init_language = (default_lang: string): Language => {
  // language data
  let language: Language = {
    iso     : default_lang,
    in_url  : false,
    country : default_lang
  }

  // attempt to get locale from url
  if (uri_lang !== undefined && uri_lang !== '/' && languages[uri_lang] !== undefined) {
    language.iso = uri_lang
    language.in_url = true
    language.country = uri_lang
  }

  language.country = country_iso2[language.country]

  // console.log(language, uri_lang, languages[uri_lang])

  return language
}


export { init_language, Language, languages, country_iso2 }
