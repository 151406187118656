import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["alt", "src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.prompt_lang_select && $setup.prompt_lang_select(...args))),
    class: "lang-select link"
  }, [
    _createElementVNode("img", {
      alt: $setup.config.lang.country,
      src: `https://hatscripts.github.io/circle-flags/flags/${$setup.config.lang.country}.svg`,
      width: "24"
    }, null, 8, _hoisted_1),
    _createElementVNode("span", null, _toDisplayString($setup.languages[$setup.config.lang.iso]), 1)
  ]))
}