
export default {
  name: 'DialcodeFlag',
  props: {
    country: undefined
  },
  data () {
    return {
      img: undefined
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.img = this.country.toLowerCase()
    })
  }
}
