<template>
  <div class="icon-block" :class="[{'vertical': vertical === true}, {'align-c': align === 'center'}]">
    <icon :icon="icon" :class="(color !== '' ? 'text-' + color : '' )"/>
    <div>
      <slot/>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/icons/icon'
export default {
  name: 'IconBlock',
  components: { Icon },
  props: [
    'vertical',
    'align',
    'icon',
    'color'
  ]
}
</script>
