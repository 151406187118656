import { config } from '@/conf'

class XHR {
  endpoint:    string
  headers:     {[key: string]: string}
  credentials: boolean

  // xhr constructor
  constructor (conf: XHRConf = {}, zero_conf: boolean = true) {
    // build xhr endpoint based on app configuration and node environment. the endpoint
    // defined in xhr config set in parameter conf takes first priority. if undefined,
    // the endpoint is set to local address if node env is 'development' or the app domain
    // defined in app config is returned.
    this.endpoint = build_endpoint(conf.endpoint)

    // assign default headers if zero_conf is false
    this.headers = {}
    if (!zero_conf) {
      this.headers = {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json; charset=utf-8'
      }
    }
    this.set.headers(conf.headers)

    // credentials mode enabled/disabled
    this.credentials = conf.credentials === undefined ? false : conf.credentials
  }

  post (path: string, data: string) {
    return this.submit({ method: 'post', path: path, data: data })
  }

  get (path: string): Promise<Response> {
    return this.submit({ method: 'get', path: path })
  }

  submit (r: Request): Promise<Response> {
    return new Promise((resolve, reject) => {
      // xml http request
      const xhr = new XMLHttpRequest()
      // set xhr with credentials  to true
      xhr.withCredentials = this.credentials

      // start the xhr request with method and url as pointed to by parameter req
      xhr.open(r.method.toUpperCase(), this.endpoint + r.path)

      // error handler
      xhr.onerror = () => {
        reject(xhr.response || xhr.status)
      }

      // handle xhr response event
      xhr.onload = () => {
        // status ok
        if (xhr.status >= 200 && xhr.status < 300) {
          // resolve the formatted output
          resolve(new Response(xhr.response))
        }

        // status unauthorized
        else if (xhr.status === 403) {
          reject(xhr.response || xhr.status)
        }

        // status unknown error
        else {
          // reject with xhr status
          reject(xhr.response || xhr.status)
        }
      }

      // set xhr headers
      for (const key in this.headers) {
        if (this.headers.hasOwnProperty(key)) {
          xhr.setRequestHeader(key, this.headers[key])
        }
      }

      // encode the payload object and send
      xhr.send(r.data || '')
    })
  }

  // xhr properties setter
  set = {
    key: (val: string) => {
      this.set.header('key', val)
    },
    header: (key: string, value: string) => {
      this.headers[key] = value
    },
    headers: (headers?: {[key: string]: string}) => {
      if (headers) this.headers = Object.assign(this.headers, headers)
    }
  }
}

// XHR configuration interface
interface XHRConf {
  mode?:        'local'|'public'
  endpoint?:    string
  headers?:     {[key: string]: string},
  credentials?: boolean
}

interface Request {
  method   : 'post'|'get'|'update'|'delete',
  headers ?: {[key: string]: string},
  path     : string,
  data    ?: any,
}

class Response {
  data?:  string

  constructor (body?: any) {
    this.data = body
  }

  decode <t>(): t|undefined {
    if (!this.data) return undefined
    return JSON.parse(this.data)
  }
}

// returns endpoint based on config and environment
const build_endpoint = (conf?: string) : string => {
  // return configured endpoint
  if (conf !== undefined) {
    return conf
  }

  // dev mode
  if (process.env.NODE_ENV === 'development') {
    console.log("xhr configured in dev mode [endpoint: http://127.0.0.1:10001]")
    return "http://127.0.0.1:10001/api"
  }

  console.log(config.host)

  // api endpoint defined in config
  return "https://"+config.host+"/api"
}

export {
  XHR,
  XHRConf,
  Response
}
